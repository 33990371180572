import React from "react";
import View from "./views/View";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar, Footer } from "./components";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<View />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
