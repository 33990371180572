import React from "react";
import TimeLine from "../components/TimeLine";

const EducationScene = ({ EducationData }) => {
  return (
    <>
      <div className="flex justify-center w-full">
        <div className="w-[400px]">
          <TimeLine
            TimeLine={EducationData.TimeLine}
            items={EducationData.Education}
          />
        </div>
      </div>
    </>
  );
};

export default EducationScene;
