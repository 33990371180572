import React from 'react';
import {
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaGithub
} from 'react-icons/fa';

function Footer() {
  return (
    <div className='border-t bg-Secondary pt-0 px-0 pb-8 flex flex-col justify-center items-center'>
      <div className='flex justify-center items-center w-full max-w-screen-lg mt-10 my-auto mb-0 flex-col'>
        <div className='text-white mb-4'>David Vonck © 2022</div>
        <div className='flex justify-between items-center w-60'>
          <a href='https://www.instagram.com/david_vonck/?hl=nl' className='text-white text-2xl' target='_blank' aria-label='Instagram'>
            <FaInstagram />
          </a>
          <a href='https://twitter.com/David35677311' className='text-white text-2xl' target='_blank' aria-label='Twitter'>
            <FaTwitter />
          </a>
          <a href='https://www.linkedin.com/in/david-vonck-1597291ba' className='text-white text-2xl' target='_blank' aria-label='LinkedIn'>
            <FaLinkedin />
          </a>
          <a href='https://github.com/Sparky018' className='text-white text-2xl' target='_blank' aria-label='Github'>
            <FaGithub />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
