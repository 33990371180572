import React, { useState } from "react";
import Popup from "../components/popup/Popup";
import PopupPost from "../components/popup/PopupPost";
import Post from "../components/Post";

function PostScene({ PostData }) {
  const [click, setClick] = useState(false);
  const [popup, setPopup] = useState([]);

  const getData = (post) => {
    setPopup(post);
    return setClick(true);
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-8">
        <Post Posts={PostData.Posts} getData={getData} />
      </div>
      <Popup click={click} setClick={setClick}>
        <PopupPost popup={popup} />
      </Popup>
    </>
  );
}

export default PostScene;
