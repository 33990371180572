import React from "react";

const Post = (props) => {
  return props.Posts.map((post, index) => (
    <button onClick={() => props.getData(post)} key={index} className="flex flex-col">
      <img
        className="object-cover h-[250px] pointer-events-none"
        src={post.ImgSrc}
        alt={post.Title}
      />
      <h1 className="text-TextLight text-2xl">{post.Title}</h1>
    </button>
  ));
};

export default Post;