import React from "react";
import { IoMdSchool } from "react-icons/io";
import { GiAchievement } from "react-icons/gi";

const TimeLine = ({ items, TimeLine }) => {
  return items.map((item, index) => (
    <ol
      key={index}
      className="flex flex-col md:flex-row relative border-l-2 border-Secondary dark:border-white"
    >
      <li className="mb-10 ml-4">
        <div className="absolute bg-white rounded-full mt-1.5 -left-[13px] border-2 border-Secondary dark:border-white dark:bg-Secondary">
          {TimeLine === "Education" ? (
            <IoMdSchool className="dark:text-white text-xl text-Secondary" />
          ) : (
            <GiAchievement className="text-Secondary text-xl dark:text-white" />
          )}
        </div>
        <div className="flex flex-wrap flex-col justify-start">
          <h1 className="text-2xl text-TextLight dark:text-white">
            {item.Title}
          </h1>
          <div className="my-1 text-sm font-normal leading-none dark:text-TextLight text-gray-400">
            {item.StartDate} - {item.EndDate}
          </div>
        </div>
        <p className="my-2 text-base font-normal text-TextLight dark:text-white">
          {item.Text}
        </p>
      </li>
    </ol>
  ));
};

export default TimeLine;
