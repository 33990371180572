import React, { useState } from "react";
import { Link as LinkScroll } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Link as LinkRouter } from "react-router-dom";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="z-50 bg-Secondary h-20 flex justify-center items-center text-sm sticky top-0">
            <div className="Container flex justify-between h-20">
              <LinkRouter to="/" className="text-white justify-start cursor-pointer text-4xl flex items-center" onClick={closeMobileMenu}>
                David
              </LinkRouter>
              <div className="MobileIcon" onClick={handleClick}>
                {click ? <FaTimes /> : <FaBars />}
              </div>
              <ul className="NavMenu" onClick={handleClick} click={click}>
                <li className="NavItem">
                  <LinkScroll
                    className="Link"
                    to="home"
                    spy={true}
                    smooth={true}
                    onClick={closeMobileMenu}
                  >
                    Home
                  </LinkScroll>
                </li>
                <li className="NavItem">
                  <LinkScroll
                    className="Link"
                    to="post"
                    spy={true}
                    smooth={true}
                    onClick={closeMobileMenu}
                  >
                    Posts
                  </LinkScroll>
                </li>
                <li className="NavItem">
                  <LinkScroll
                    className="Link"
                    to="about"
                    spy={true}
                    smooth={true}
                    onClick={closeMobileMenu}
                  >
                    About
                  </LinkScroll>
                </li>
                <li className="NavItem">
                  <LinkScroll
                    className="Link"
                    to="experience"
                    spy={true}
                    smooth={true}
                    onClick={closeMobileMenu}
                  >
                    Experience
                  </LinkScroll>
                </li>
                <li className="NavItem">
                  <LinkScroll
                    className="Link"
                    to="education"
                    spy={true}
                    smooth={true}
                    onClick={closeMobileMenu}
                  >
                    Education
                  </LinkScroll>
                </li>
                <li className="NavItem">
                  <LinkScroll
                    className="Link"
                    to="contact"
                    spy={true}
                    smooth={true}
                    onClick={closeMobileMenu}
                  >
                    Contact
                  </LinkScroll>
                </li>
              </ul>
            </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
