import React from "react";

function ContactScene() {
  return (
    <>
      <div className="flex flex-col mb-10 mx-auto">
        <div className="flex justify-center items-center">
          <form
            action="https://getform.io/f/(customSlugHere)"
            method="POST"
            className="flex flex-col w-full md:w-7/12"
          >
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="p-2 bg-transparent border-2 border-Secondary rounded-md focus:outline-none text-TextLight"
            />
            <input
              type="text"
              name="email"
              placeholder="Email"
              className="my-2 p-2 bg-transparent border-2 border-Secondary rounded-md focus:outline-none text-TextLight"
            />
            <textarea
              name="message"
              placeholder="Message"
              rows="10"
              className="p-2 mb-4 bg-transparent border-2 border-Secondary rounded-md focus:outline-none text-TextLight"
            />
            <button type="button" className="Button">
              Send
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactScene;
