import React from "react";
import TimeLine from "../components/TimeLine";

const ExperienceScene = ({ ExperienceData }) => {
  return (
    <>
      <div className="flex justify-center w-full">
        <div className="w-[400px]">
          <TimeLine
            TimeLine={ExperienceData.TimeLine}
            items={ExperienceData.Experience}
          />
        </div>
      </div>
    </>
  );
};

export default ExperienceScene;
