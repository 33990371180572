import React from "react";
import { FaTimes } from "react-icons/fa";

const Popup = (props) => {
  return props.click ? (
    <div
      className="Container fixed left-0 top-0 w-full h-full flex items-center justify-center bg-white bg-opacity-20 z-50"
      onClick={() => props.setClick(false)}
    >
        <div className="relative w-full bg-Secondary">
          <button className="absolute right-0 m-4" onClick={() => props.setClick(false)}>
            <FaTimes className="text-Primary" />
          </button>
          {props.children}
        </div>
    </div>
  ) : null;
};

export default Popup;