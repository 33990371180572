import React from "react";
import { FaGithub } from "react-icons/fa";

const PopupPost = ({ popup }) => {
  return (
    <div className="flex flex-row">
      <img
        className="object-cover w-[700px] h-[500px]"
        src={popup.ImgSrc}
        alt={popup.Title}
      />
      <div className="dark p-6">
        <h1 className="Heading">{popup.Title}</h1>
        <div className="flex flex-col border-y-2 border-white">
          <h2 className="text-white">gebruikte talen / frameworks</h2>
          <div className="flex">
            {popup.Lang?.map((lang, index) => (
              <div
                key={index}
                className="bg-white border-[1px] border-white rounded-lg p-[2px] m-1"
              >
                <span className="text-TextLight">{lang}</span>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="text-white my-3">{popup.Discription}</p>
          <div className="flex justify-between">
            {popup.GitHub ? (
              <a href={popup.GitHubUrl}>
                <FaGithub />
              </a>
            ) : null}
            {popup.Link ? (
              <a href={popup.Url} className="Button">
                See project!
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupPost;
