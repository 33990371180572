import React from "react";

const AboutScene = ({ AboutData }) => {
  return (
    <>
      <p className="text-white">{AboutData.About}</p>
    </>
  );
};

export default AboutScene;
