import React from "react";

function Landing({ LandingData }) {
  return (
    <>
      <div className="flex mt-0 mx-[-15px] mb-[-15px] flex-wrap items-center flex-row">
        <div className="Column">
          <div className="TextWrapper">
            <h1 className="Heading">{LandingData.HeadLine}</h1>
            <p className="Description" LightTH={LandingData.LightTH}>
              {LandingData.DescriptionText}
            </p>
            <a className="Button" href={LandingData.ButtonLink} target="_blank">
              {LandingData.ButtonLabel}
            </a>
          </div>
        </div>
        <div className="Column">
          <div className="flex max-w-[400px] max-h-[400px] justify-center">
            <img className="rounded-full" src={LandingData.ImgSrc} alt={LandingData.ImgAlt} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
