import { avatar, secrethitler, portfolio, restapi } from "../images";

export const LandingData = {
  //main
  Id: "home",
  //textwrapper
  HeadLine: "Hi!, I'm David",
  DescriptionText: "Student Software development",
  //button
  ButtonLink: "https://www.linkedin.com/in/david-vonck-1597291ba",
  ButtonLabel: "Get in touch",
  //imgwrapper
  ImgSrc: avatar,
  ImgAlt: "Profiel foto",
};

export const PostData = {
  //main
  Id: "post",
  //textwrapper
  HeadLine: "Posts",
  //Posts
  Posts: [
    // {
    //   Link: false,
    //   Url: "/",
    //   GitHub: false,
    //   GitHubLink: "/",
    //   ImgSrc: portfolio,
    //   Title: "Portfolio",
    //   Lang: ["React"],
    //   Discription:
    //     "Dit is de website waar je momenteel op bent. Deze heb ik gebouwd in het frontend framework React js omdat ik dit het fijnste framework vind.  Deze site heb ik in ongeveer 2 weken gebouwd. Ik heb het zo gemaakt dat je heel makelijk nog een pagina zou kunnen toevoegen. Deze pagina’s worden gegenereerd met behulp van een data file, die ik heb ontwikkeld.",
    // },
    // {
    //   Link: false,
    //   Url: "/",
    //   GitHub: false,
    //   GitHubLink: "/",
    //   ImgSrc: secrethitler,
    //   Title: "Secret Hitler",
    //   Lang: ["vue", "Express.js"],
    //   Discription:
    //     "Dit project heb ik samen met mijn projectgroep/scrumgroep op school gedaan. Het spel “secret hitler” is al een bestaand bordspel, dat veel lijkt op het kaartspel weerwolven. Samen met mijn groepje heb ik er een werkende online game van gemaakt. Dit spel kan gespeeld worden met minimaal 5 en maximaal 6 spelers. Dit spel is niet geschikt om te spelen op een smartphone.",
    // },
    // {
    //   Link: false,
    //   Url: "/",
    //   GitHub: false,
    //   GitHubLink: "/",
    //   ImgSrc: restapi,
    //   Title: "Rest-api",
    //   Lang: ["Laravel"],
    //   Discription:
    //     "Dit project heb ik gedaan tijdens mijn stageperiode bij uwboeking. Deze API kan gebruikt worden voor het ophalen van de reserveringen in het reserveringsysteem van uwboeking. Dit was nodig omdat de oude API niet efficiënt genoeg was.",
    // },
    // {
    //   Link: false,
    //   Url: "/",
    //   GitHub: false,
    //   GitHubLink: "/",
    //   ImgSrc: restapi,
    //   Title: "",
    //   Lang: [""],
    //   Discription: "",
    // },
  ],
};

export const AboutData = {
  //main
  Id: "about",
  //text
  HeadLine: "About",
  About: "Hallo, mijn naam is David Vonck, ik ben 19 jaar oud. Ik ben momenteel bezig met een opleiding: Software Development bij Landstede in Zwolle. Tijdens deze opleiding heb ik al veel projecten gemaakt en heb ik veel geleerd over het werken met scrum. Naast mijn opleiding vind ik het leuk om te sporten of iets te doen met vrienden. Bovendien vind ik het erg leuk om met mijn drone te vliegen.",
};

export const SkillData = {
  //skills
  Skill: [
    "Html",
    "Javascript",
    "Php",
    "C#",
    "React js",
    "React Native",
    "Flutter",
    "Laravel",
  ],
};

export const ExperienceData = {
  Id: "experience",
  TimeLine: "Experience",
  //text
  HeadLine: "Experience",
  //Experience
  Experience: [
    // {
    //   Title: "",
    //   StartDate: "",
    //   EndDate: "",
    //   Text: "",
    // },
    // {
    //   Title: "Humphreys",
    //   StartDate: "2022",
    //   EndDate: "Today",
    //   Text: "Als bijbaantje in de bediening van Humphreys.",
    // },
    // {
    //   Title: "Djurve",
    //   StartDate: "2022",
    //   EndDate: "Today",
    //   Text: "Bijbaan bij Djurve als developer",
    // },
    // {
    //   Title: "Uwboeking.com",
    //   StartDate: "2021",
    //   EndDate: "2022",
    //   Text: "Stage gelopen bij uwboeking; hier heb ik de rest api herschreven.",
    // },
    // {
    //   Title: "Azerty",
    //   StartDate: "2020",
    //   EndDate: "2022",
    //   Text: "Bijbaan bij Azerty op de assemblage-afdeling. Op de assemblage afdeling heb ik rond de 700 pc’s gebouw",
    // },
    // {
    //   Title: "Repareren en bouwen van computers",
    //   StartDate: "2019",
    //   EndDate: "Today",
    //   Text: "Nadat ik bij Gamepc heb gewerkt, heb ik naast mijn opleiding voor mezelf gewerkt. Ik nam opdrachten aan via marktplaats voor het bouwen en repareren van computers. Dit doe ik nu nog steeds af",
    // },
    // {
    //   Title: "Gamepc.nl",
    //   StartDate: "2019 Begin zomervakantie",
    //   EndDate: "2019 Einde zomervakantie",
    //   Text: "Zomerbaan bij Gamepc op de assemblage-afdeling, waar ik meer dan 150 pc’s heb gebouwd",
    // },
  ],
};

export const EducationData = {
  Id: "education",
  TimeLine: "Education",
  //text
  HeadLine: "Education",
  //Education
  Education: [
    // {
    //   Title: "",
    //   StartDate: "",
    //   EndDate: "",
    //   Text: "",
    // },
    // {
    //   Title: "Landstede Zwolle",
    //   StartDate: "2020",
    //   EndDate: "Today",
    //   Text: "begonnen met mbo-niveau 4 software developer",
    // },
    // {
    //   Title: "Greijdanus Zwolle",
    //   StartDate: "2016",
    //   EndDate: "2020",
    //   Text: "Ik heb hier mijn Tl/Havo diploma gehaald",
    // },
  ],
};
