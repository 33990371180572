import React from "react";
import {
  LandingScene,
  PostScene,
  AboutScene,
  ExperienceScene,
  EducationScene,
  ContactScene,
} from "../scenes";
import {
  LandingData,
  PostData,
  AboutData,
  ExperienceData,
  EducationData,
} from "../data/ViewData";

function View() {
  return (
    <>
      <div className="dark">
        <section id="home" className="Section">
          <div className="Container">
            <LandingScene LandingData={LandingData} />
          </div>
        </section>
      </div>
      <div className="">
        <section id="post" className="Section">
          <div className="Container">
            <h1 className="Heading">Posts</h1>
            <PostScene PostData={PostData} />
          </div>
        </section>
      </div>
      <div className="dark">
        <section id="about" className="Section">
          <div className="Container">
            <h1 className="Heading">About</h1>
            <AboutScene AboutData={AboutData} />
          </div>
        </section>
      </div>
      <div className="">
        <section id="experience" className="Section">
          <div className="Container">
            <h1 className="Heading">Experience</h1>
            <ExperienceScene ExperienceData={ExperienceData} />
          </div>
        </section>
      </div>
      <div className="dark">
        <section id="education" className="Section">
          <div className="Container">
            <h1 className="Heading">Education</h1>
            <EducationScene EducationData={EducationData} />
          </div>
        </section>
      </div>
      <div className="">
        <section id="contact" className="Section">
          <div className="Container">
            <h1 className="Heading">Contact</h1>
            <ContactScene />
          </div>
        </section>
      </div>
    </>
  );
}

export default View;
